import { useCustomPageTemplate } from 'api/queries/useCustomPageTemplate';
import Layout from 'components/Layout/Layout';
import CustomPageTemplateListItem from 'components/PageTemplateList/CustomPageTemplateListItem';
import { useLocation } from 'react-router-dom';
import BarLoader from 'react-spinners/BarLoader';

const CustomPageTemplateListScreen = () => {
  const searchParams = new URLSearchParams(useLocation().search);
  const templateSlug = searchParams.get('templateSlug');
  const { data, isLoading } = useCustomPageTemplate(templateSlug ?? '');
  return (
    <>
      {isLoading ? (
        <BarLoader height='4px' width='100%' loading={isLoading} color='#00AAE5' />
      ) : (
        <Layout pageTitle={`Template Viewer: ${templateSlug}`} showGoBackLink={true}>
          <div className='shadow overflow-hidden border-b border-gray-200 sm:rounded-lg max-w-7xl mx-auto'>
            <table className='min-w-full  divide-y divide-gray-200'>
              <thead className='bg-blue-tint'>
                <tr>
                  <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Business Name
                  </th>
                  <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Page Name
                  </th>
                  <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Page Url
                  </th>
                  <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Page Status
                  </th>
                  <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Visibility
                  </th>
                  <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Pages: {data?.length ?? '0'}
                  </th>
                </tr>
              </thead>
              <tbody className='bg-white divide-y divide-gray-200'>
                {data
                  ? data?.map((pagetemplate, index) => <CustomPageTemplateListItem key={index} index={index} pagetemplate={pagetemplate} />)
                  : null}
              </tbody>
            </table>
          </div>
        </Layout>
      )}
    </>
  );
};

export default CustomPageTemplateListScreen;
