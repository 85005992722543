import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';

// Components
import NotificationMessage, { NotificationStyles } from 'components/Notification';
import SavePrompt from 'components/SavePrompt';
import Layout from 'components/Layout/Layout';
import Block from 'components/Inputs/Block';
import ApprovalButtons from 'components/Buttons/ApprovalButtons';
import BarLoader from 'react-spinners/BarLoader';
import InputModal from 'components/Modals/InputModal';
// Commands
import { Page } from 'models/page';
import { usePage } from 'api/queries/usePage';
import DeleteButton from 'components/Buttons/DeleteButton';
import { DeletePage } from 'commands/DeletePage';
import { convertCamelCaseToTitle } from 'components/util/convertCamelCaseToTitle';
import { Approve, Reject, RequestApproval } from 'commands/Approval';
import PreviewButton from 'components/Buttons/PreviewButton';
import { CreatePageRedirect } from 'commands/CreatePageRedirect';

import { PublishUmbracoPage } from 'commands/PublishUmbracoPage';
import ErrorModal from 'components/Modals/ErrorModal';
import { NotifyPageUpdate } from 'commands/NotifyPageUpdate';
import { useCommands } from 'hooks/useCommands';
import { useBusinessDetails } from 'api/queries/useBusinessDetails';
import RenameButton from 'components/Buttons/RenameButton';
import { usePages } from 'api/queries/usePages';
import BooleanInput from 'components/Inputs/BooleanInput';
import { ChangePageVisibility } from 'commands/ChangePageVisibility';

const PageEditScreen = () => {
  const { commands, showError, showSuccess, errorMessage, setShowSuccess } = useCommands();
  const location = useLocation<{ templateData?: Page; id: string }>();
  const { slug, businessName } = useParams<{ slug: string; businessName: string }>();

  const { data: businessData } = useBusinessDetails(businessName);
  const isByob = businessData?.details?.byob;

  const template = location?.state?.templateData;

  const { data: pages, error: pageError, isLoading: isLoadingPages, isError: isPageError } = usePages(businessName);

  const pageNames: string[] = [];
  const pageSlug: string[] = [];
  pages?.forEach(p =>
    p.pages?.forEach(pg => {
      pageNames.push(pg.title);
      pageSlug.push(pg.slug);
    })
  );

  const { data: page, isLoading, error, isError } = usePage(businessName, template?.slug ?? slug, !!template);

  const [blockState, setBlockState] = useState<{ blockId: string; isValid: boolean }[]>([]);

  const [del, setDel] = useState(false);

  const verifyMessage = 'Enter in redirect';

  const [Message, setMessage] = useState<string>(verifyMessage);

  const [redirectvalue, setRedirectValue] = useState<string>('');

  const history = useHistory();

  useEffect(() => {
    if (showSuccess) {
      window.location.reload();
    }
  }, [showSuccess]);

  function clear() {
    window.location.reload();
  }

  function clearCache() {
    history.goBack();
    setTimeout(clear, 500);
  }

  const handlePageRedirect = () => {
    var pageredirectinput = [];
    pageredirectinput.push(location.state.id, redirectvalue, businessName);
    commands.add(new CreatePageRedirect(pageredirectinput));
    commands.add(new DeletePage(location.state.id)).send();
    setTimeout(clearCache, 1500);
  };

  const handleClose = () => {
    setDel(false);
    commands.add(new DeletePage(location.state.id)).send();
    setTimeout(clearCache, 1250);
  };

  const pageRedirectIsValid = async (newValue: string) => {
    var pass = false;
    newValue !== '' ? (pass = true) : (pass = false);
    pass === true ? (pass = false) : (pass = true);
    setMessage(pass ? 'Enter in redirect' : 'Submit page redirect');
    return pass;
  };

  const handleToggleHidePage = (value: string, id: string) => {
    const val = value;
    commands.add(new ChangePageVisibility(val.toString(), id));
  };

  return (
    <>
      <Layout
        showGoBackLink={true}
        pageTitle={`${isLoading ? 'loading' : 'Editing: ' + page?.title}`}
        buttons={
          <>
            {page && page?.custom && (
              <>
                <div className='flex'>
                  {typeof page?.visibility !== 'undefined' ? (
                    <BooleanInput
                      id={page.id}
                      handleFieldValue={handleToggleHidePage}
                      commands={commands}
                      isBlockToggle={false}
                      label={'Visible in Navigation'}
                      value={page?.visibility}
                    />
                  ) : null}
                  <DeleteButton
                    addDeleteCommand={() => {
                      setDel(true);
                    }}
                    autoClear={false}
                    isMedia={false}
                    setSmall={true}
                  />
                </div>
                <RenameButton text={'Rename'} pageTitles={pageNames} pageSlugs={pageSlug} businessData={businessData} pageData={page} />
              </>
            )}

            <PreviewButton businessName={businessName} slug={slug} enable={true} />
            <ApprovalButtons
              approval={page?.approval}
              addApproveCommand={message => commands.add(new Approve(message, page!.id)).send()}
              addRejectCommand={message => commands.add(new Reject(message, page!.id)).send()}
              addRequestApprovalCommand={() => commands.add(new RequestApproval('', page!.id)).send()}
              addPublishCommand={message => {
                commands.add(new PublishUmbracoPage(slug, message));
                commands.add(new NotifyPageUpdate(slug)).send();
              }}
              save={() => commands.send()}
            />
          </>
        }
      >
        <div className='absolute inset-0  sm:px-6 lg:px-8'>
          <NotificationMessage type={NotificationStyles.Happy} show={showSuccess} setShow={setShowSuccess} message='Successfully sent!' />
          <ErrorModal open={isError || showError} error={error} errorMessage={errorMessage} />
          <div className='h-full py-8'>
            <div className=' pb-16'>
              <div className='space-y-6'>
                {isLoading ? (
                  <BarLoader height='4px' width='100%' loading={isLoading} color='#00AAE5' />
                ) : (
                  <>
                    <div>
                      {page?.properties && (
                        <Block
                          buId={page!.id}
                          blockState={blockState}
                          setBlockState={setBlockState}
                          alias='Page Properties'
                          fields={page!.properties}
                          commands={commands}
                        />
                      )}
                    </div>
                    <div>
                      {page &&
                        page?.blocks.map(block =>
                          !block?.hideForAll ? (
                            isByob ? (
                              block?.hideForBYOB ? null : (
                                <Block
                                  buId={block.buId}
                                  blockState={blockState}
                                  setBlockState={setBlockState}
                                  alias={convertCamelCaseToTitle(block.alias)}
                                  fields={block.fields}
                                  commands={commands}
                                  key={block.buId}
                                />
                              )
                            ) : (
                              <Block
                                buId={block.buId}
                                blockState={blockState}
                                setBlockState={setBlockState}
                                alias={convertCamelCaseToTitle(block.alias)}
                                fields={block.fields}
                                commands={commands}
                                key={block.buId}
                              />
                            )
                          ) : null
                        )}
                    </div>
                  </>
                )}
                <SavePrompt />
              </div>
            </div>
          </div>
        </div>
      </Layout>

      {del ? (
        <InputModal
          saveText={Message}
          title={'Delete page - Set redirect for page'}
          description={`Enter in the new url path for users who visit this deleted page, the new url should be: "pagetype/pagename".`}
          open={del}
          inputValue={redirectvalue}
          textArea={true}
          setInputValue={setRedirectValue}
          sendHandler={handlePageRedirect}
          customValidation={value => pageRedirectIsValid(value)}
          red={false}
          onClose={() => handleClose()}
        />
      ) : null}
    </>
  );
};

export default PageEditScreen;
